import React from 'react';

export const MeContext = React.createContext<{
  clinicInfo: {
    primaryColor: string;
    accentColor: string;
    flags: string[];
    doNotAllowUnrelatedRegions?: boolean;
  };
}>({
  clinicInfo: {
    primaryColor: '#4f46e5',
    accentColor: '#4f46e5',
    flags: [],
    doNotAllowUnrelatedRegions: false
  },
});
